'use client'

import {useForm} from "react-hook-form";

import {SDivider} from "@/components/atoms/divider";
import {Section} from "@/components/atoms/sections";
import {Title, TitleLabel} from "@/components/atoms/typographies";
import {AsideLayout, ContentLayout} from "@/components/molecules/layouts";
import {
  ControlledButtonSubmit,
  ControlledForm,
  ControlledInputPassword,
  ControlledInputText
} from "@/components/molecules/form";
import {useLogInMutation} from "@/lib/client/slices/users";
import {errors, parseError} from "@/lib/tools/errors";
import {ResponseStatusMessage} from "@/components/atoms/ResponseStatusMessage";
import {useCallback} from "react";
import {useReCaptcha} from "next-recaptcha-v3";
import Link from "next/link";
import { SLinkButton } from "@/components/atoms/button";
import { Ad } from "@/components/atoms/ad";


export default function Login() {
  const {executeRecaptcha} = useReCaptcha();
  const [login, status] = useLogInMutation()
  const form = useForm<any>({
    defaultValues: {
      email: '', password: '', token: ''
    }
  })


  const handleSubmit = useCallback((data: { email: string, password: string, token: string }) => {
    console.log('execute login 1')
    executeRecaptcha("form_login")
      .then(token => login({...data, token: token}))
      .then((r: any) => {
        console.log('execute login response')
        if (r && !r.error) {
          window.location.href = '/account'
        }
      })
      .catch((e: any) => {
        const err = parseError(e)
        if (err == errors.confirm_email) {
          window.location.href = `/confirm-account?email=${data.email}`
          return
        }
      })
  }, [executeRecaptcha, login]);

  return (<>
    <ContentLayout>
      <Title>Connexion</Title>
      <TitleLabel>Rejoignez la communauté !</TitleLabel>
      <SDivider/>

      <Section>
        <ResponseStatusMessage status={status} successMessage="Connexion réussie. Redirection en cours..."
                               loadingMessage={"Connexion en cours..."}/>
        <ControlledForm useForm={form} onSubmit={form.handleSubmit(handleSubmit)}>
          <div className={'flex flex-col justify-center items-center m-2 p-2'}>
            <div>
              <ControlledInputText useForm={form} required={true} disabled={status.isLoading} label={"Email"}
                                   name={"email"}/>
              <ControlledInputPassword useForm={form} required={true} disabled={status.isLoading} label={"Mot de passe"}
                                       name={"password"}/>
            </div>
            <ControlledButtonSubmit useForm={form} loading={status.isLoading} disabled={status.isLoading}
                                    label={"Connexion"}/>
            <div className="bg-default w-full h-[2px] m-6"></div>
            <div>Vous avez un problème de connection ou identifiants oubliés?</div>
            <SLinkButton href={"/reset-password"} variant="contrast">Débloquez mon compte</SLinkButton>
          </div>
        </ControlledForm>
      </Section>
    </ContentLayout>
    <AsideLayout>
    <Section className="flex justify-center">
        <Ad format={'pave'}/>
      </Section>
    </AsideLayout>
  </>)
}
