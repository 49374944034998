import {ErrorMessage, InfoMessage, SuccessMessage} from "@/components/atoms/typographies";
import {useEffect, useState} from "react";
import {displayError, parseError} from "@/lib/tools/errors";

export function ResponseStatusMessage({status, successMessage, loadingMessage}: {
  status: any,
  successMessage: string,
  loadingMessage?: string
}) {
  const [message, setMessage] = useState(<div className="p-2 m-2"></div>)

  useEffect(() => {
    if (status.isError) {
      setMessage(<ErrorMessage>{displayError(parseError(status.error))}</ErrorMessage>)
    }
    if (status.isLoading) {
      setMessage(<SuccessMessage>{successMessage}</SuccessMessage>)
    }

    if (status.isLoading) {
      setMessage(<InfoMessage>{loadingMessage || 'Chargement...'}</InfoMessage>)
    }
  }, [status])

  return message
}